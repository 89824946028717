import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook, faInstagram, faLinkedin, faMedium, faTiktok, faTwitter, faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { useIntl, FormattedMessage } from 'react-intl';

import nullableFormattedMessage from 'src/util/i18n/nullable';

config.autoAddCss = true;

const SocialFontAwesomeIcon = styled(FontAwesomeIcon)`
  fontSize: 1.5rem;
  lineHeight: 1rem;
  marginTop: -.25rem;
`;

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - Footer component
 */
function Footer(props) {
  const { versionUI } = props;

  const intl = useIntl();

  return (
    <footer>
      <div className="container">
        <hr style={{ marginTop: '2em' }} />
        <Nav>
          {
            nullableFormattedMessage({
              intl,
              id: 'footer.navigation.social_media.facebook.url',
              message: (
                <NavItem className="social">
                  <NavLink href={intl.formatMessage({ id: 'footer.navigation.social_media.facebook.url', defaultMessage: 'https://facebook.com/enosi.energy' })}>
                    <SocialFontAwesomeIcon icon={faFacebook} />
                  </NavLink>
                </NavItem>
              ),
            })
          }
          {
            nullableFormattedMessage({
              intl,
              id: 'footer.navigation.social_media.instagram.url',
              message: (
                <NavItem className="social">
                  <NavLink href={intl.formatMessage({ id: 'footer.navigation.social_media.instagram.url', defaultMessage: 'https://instagram.com/enosi.energy' })}>
                    <SocialFontAwesomeIcon icon={faInstagram} />
                  </NavLink>
                </NavItem>
              ),
            })
          }
          {
            nullableFormattedMessage({
              intl,
              id: 'footer.navigation.social_media.linkedin.url',
              message: (
                <NavItem className="social">
                  <NavLink href={intl.formatMessage({ id: 'footer.navigation.social_media.linkedin.url', defaultMessage: 'https://www.linkedin.com/company/enosienergy' })}>
                    <SocialFontAwesomeIcon icon={faLinkedin} />
                  </NavLink>
                </NavItem>
              ),
            })
          }
          {
            nullableFormattedMessage({
              intl,
              id: 'footer.navigation.social_media.medium.url',
              message: (
                <NavItem className="social">
                  <NavLink href={intl.formatMessage({ id: 'footer.navigation.social_media.medium.url', defaultMessage: 'https://medium.com/enosi' })}>
                    <SocialFontAwesomeIcon icon={faMedium} />
                  </NavLink>
                </NavItem>
              ),
            })
          }
          {
            nullableFormattedMessage({
              intl,
              id: 'footer.navigation.social_media.tiktok.url',
              message: (
                <NavItem className="social">
                  <NavLink href={intl.formatMessage({ id: 'footer.navigation.social_media.tiktok.url', defaultMessage: '#' })}>
                    <SocialFontAwesomeIcon icon={faTiktok} />
                  </NavLink>
                </NavItem>
              ),
            })
          }
          {
            nullableFormattedMessage({
              intl,
              id: 'footer.navigation.social_media.twitter.url',
              message: (
                <NavItem className="social">
                  <NavLink href={intl.formatMessage({ id: 'footer.navigation.social_media.twitter.url', defaultMessage: 'https://twitter.com/enosienergy' })}>
                    <SocialFontAwesomeIcon icon={faTwitter} />
                  </NavLink>
                </NavItem>
              ),
            })
          }
          {
            nullableFormattedMessage({
              intl,
              id: 'footer.navigation.social_media.youtube.url',
              message: (
                <NavItem className="social">
                  <NavLink href={intl.formatMessage({ id: 'footer.navigation.social_media.youtube.url', defaultMessage: 'https://youtube.com/enosienergy' })}>
                    <SocialFontAwesomeIcon icon={faYoutube} />
                  </NavLink>
                </NavItem>
              ),
            })
          }

          {
            nullableFormattedMessage({
              intl,
              id: 'footer.navigation.text0.url',
              message: (
                <NavItem>
                  <NavLink href={intl.formatMessage({ id: 'footer.navigation.text0.url', defaultMessage: 'https://enosi.energy/' })}>
                    <FormattedMessage id="footer.navigation.text0.text" defaultMessage="Enosi Australia Pty Ltd" description="Text link, usually for Enosi Australia" />
                  </NavLink>
                </NavItem>
              ),
            })
          }
          {
            nullableFormattedMessage({
              intl,
              id: 'footer.navigation.text1.url',
              message: (
                <NavItem>
                  <NavLink href={intl.formatMessage({ id: 'footer.navigation.text1.url', defaultMessage: 'https://support.enosi.energy/' })}>
                    <FormattedMessage id="footer.navigation.text1.text" defaultMessage="Support desk" description="Text link, usually for the Enosi support desk" />
                  </NavLink>
                </NavItem>
              ),
            })
          }
          {
            nullableFormattedMessage({
              intl,
              id: 'footer.navigation.text2.url',
              message: (
                <NavItem>
                  <NavLink href={intl.formatMessage({ id: 'footer.navigation.text2.url', defaultMessage: 'https://enosi.energy/terms/powertracer' })}>
                    <FormattedMessage id="footer.navigation.text2.text" defaultMessage="Terms" description="Text link, usually for Enosi terms" />
                  </NavLink>
                </NavItem>
              ),
            })
          }
          {
            nullableFormattedMessage({
              intl,
              id: 'footer.navigation.text3.url',
              message: (
                <NavItem>
                  <NavLink href={intl.formatMessage({ id: 'footer.navigation.text3.url', defaultMessage: 'https://enosi.energy/legal/privacy' })}>
                    <FormattedMessage id="footer.navigation.text3.text" defaultMessage="Privacy" description="Text link, usually for privacy legals" />
                  </NavLink>
                </NavItem>
              ),
            })
          }
          {
            nullableFormattedMessage({
              intl,
              id: 'footer.navigation.text4.url',
              message: (
                <NavItem>
                  <NavLink href={intl.formatMessage({ id: 'footer.navigation.text4.url', defaultMessage: 'footer.navigation.text4.url', description: 'Normally not shown' })}>
                    <FormattedMessage id="footer.navigation.text4.text" defaultMessage="." description="Normally blank" />
                  </NavLink>
                </NavItem>
              ),
            })
          }
          {
            nullableFormattedMessage({
              intl,
              id: 'footer.navigation.text5.url',
              message: (
                <NavItem>
                  <NavLink href={intl.formatMessage({ id: 'footer.navigation.text5.url', defaultMessage: 'footer.navigation.text5.url', description: 'Normally not shown' })}>
                    <FormattedMessage id="footer.navigation.text5.text" defaultMessage="." description="Normally blank" />
                  </NavLink>
                </NavItem>
              ),
            })
          }
        </Nav>
        {
          nullableFormattedMessage({
            intl,
            id: 'footer.mesage',
            message: (
              <p>
                <FormattedMessage
                  id="footer.message"
                  defaultMessage="Powertracer provides you with direct access to your friends and family's solar energy combined with large-scale renewable sources."
                />
              </p>
            ),
          })
        }
        <p>
          <FormattedMessage
            id="footer.version"
            defaultMessage="UI version {version}"
            description="Version information"
            values={{ version: versionUI }}
          />
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  versionUI: PropTypes.string,
};

Footer.defaultProps = {
  versionUI: 'v0.0.0-alpha',
};

export default Footer;
