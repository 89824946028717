import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { format } from 'd3-format';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Card, CardBody, Row, Col,
} from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';

import { APIConfig } from 'src/config';
import {
  COLOR_EXPORT, COLOR_EXPORT_UNTRADED,
  COLOR_IMPORT, COLOR_IMPORT_UNTRADED,
} from 'src/enosikit/components/Chart/components/chartConstants';
import {
  CONTRACTED, COMMUNITY,
  DATA_AGGREGATE_BY_METER, DATA_AGGREGATE_BY_PROPERTY,
  DATA_GROUP_BY_TRADE_TYPE, DATA_GROUP_BY_COUNTERPARTY,
  NA_SYMBOL, NOMINATED, PLATFORM_MODE_REBATE,
  RESIDUAL, UNTRADED_ENERGY_KEY,
} from 'src/util/constants';
import { i18nDecimalFormat } from 'src/util/i18n/handler';
import isNumber from 'src/util/math';
import styled from 'styled-components';
import { getEnergyOriginLabel } from './helpers/common';

const MeterLabel = styled.p.attrs({
  className: 'mb-2',
})`
  color: #adb5bd;
  font-size: 0.8em;
  margin: 0;
  padding: 0
`;

/**
 * Returns the avatar shown as part of the trade and meter cards
 * @param {object} user
 * @param {string} ring
 * @param {NOMINATED | CONTRACTED | COMMUNITY | RESIDUAL} tradeType
 * @returns {React.ReactElement} - avatar used in meter and trade cards
 */
export const avatar = (user, ring, tradeType) => {
  const baseStyle = {
    background: '#fff', borderRadius: '50%', height: '4em', width: '4em',
  };

  let avatarImage;
  if (user || tradeType === NOMINATED) {
    avatarImage = (
      <div className="property-card-user" style={{ ...baseStyle, background: '#fff' }}>
        <FontAwesomeIcon icon={faUserCircle} size="1x" style={{ height: '100%', width: '100%' }} />
      </div>
    );
  } else {
    avatarImage = <div style={{ ...baseStyle, background: '#fff' }} />;
  }
  return (
    <div style={{ borderRadius: '50%', padding: '0.25rem', background: ring }}>
      {avatarImage}
    </div>
  );
};

/**
 * Determines the card opacity based on the user selection
 * @param {string} key - normally tradeid or a combination of trade and meterid
 * @param {Array} hoverKeys
 * @param {Array} selectedKeys
 * @returns {string} - opacity for the cards.
 */
export const opacity = (key, hoverKeys, selectedKeys) => {
  // Default state
  if (hoverKeys.length === 0 && selectedKeys.length === 0) { return '100%'; }
  // Hovering
  if (hoverKeys.includes(key)) { return '100%'; }
  // Selected - with and without hovering
  if (selectedKeys.includes(key)) { return hoverKeys.length === 0 ? '100%' : '40%'; }
  // Not selected
  return '10%';
};

/**
 * Ring builds the ring details around the element.
 * @param {object} buy
 * @param {number} buy.count
 * @param {object} sell
 * @param {number} sell.count
 * @param {boolean} isUntraded
 * @returns {string} the CSS string for the ring.
 */
export const ring = (buy = { count: 0 }, sell = { count: 0 }, isUntraded = false) => {
  if (!buy && !sell) { return 'none'; }

  const buyColor = isUntraded ? COLOR_IMPORT_UNTRADED : COLOR_IMPORT;
  const sellColor = isUntraded ? COLOR_EXPORT_UNTRADED : COLOR_EXPORT;

  if (buy?.count > 0 && sell?.count > 0) { return `linear-gradient(to right, ${buyColor} 50%, ${sellColor} 50%)`; }
  if (buy?.count > 0) { return buyColor; }
  if (sell?.count > 0) { return sellColor; }

  return 'none';
};

/**
 * Provides the meter identifier for the meter label
 * @param {string} title
 * @param {string} identifier
 * @returns {string} - meter identifier.
 */
export const meterIdentifier = (title, identifier) => {
  if (!identifier) {
    return '';
  }

  return title ? ` (${identifier})` : identifier;
};

/**
 * Enrich the label with the property data if set.
 * @param {import('react-intl').IntlShape} intl - i18n react-intl
 * @param {string} label - base label.
 * @param {string} subLabel - sub label.
 * @param {object | null} property - if set with id and title for augmenting the label.
 * @param {string} property.id - id of the property.
 * @param {string} property.title - id of the property.
 * @param {string} title - title of the meter.
 * @param {string} identifier - identifier of the meter.
 * @param {object} chartView - { aggregateBy, groupBy }
 * @param {DATA_AGGREGATE_BY_PROPERTY | DATA_AGGREGATE_BY_METER} chartView.aggregateBy
 * @param {DATA_GROUP_BY_COUNTERPARTY | DATA_GROUP_BY_TRADE_TYPE} chartView.groupBy
 * @returns {React.ReactElement} - property label container.
 */
const getLabel = (intl, label, subLabel, property, title, identifier, chartView) => {
  const { groupBy } = chartView;
  const meterLabel = `${title}${identifier && meterIdentifier(title, identifier)}`;
  const energyOriginLabel = getEnergyOriginLabel(intl, label, chartView);

  if (groupBy === DATA_GROUP_BY_TRADE_TYPE) {
    return (
      <>
        {meterLabel && <MeterLabel>{meterLabel}</MeterLabel>}
        <div>{energyOriginLabel}</div>
      </>
    );
  }

  if (!property) {
    return (
      <>
        {meterLabel && <MeterLabel>{meterLabel}</MeterLabel>}
        <div>{energyOriginLabel}</div>
      </>
    );
  }

  const { id } = property;

  return (
    <>
      {meterLabel && <MeterLabel>{meterLabel}</MeterLabel>}
      <a href={`/properties/${id}`}>{energyOriginLabel}</a>
      <div className="mt-2">{subLabel}</div>
    </>
  );
};

/**
 * Build and return the content for the meter cards.
 * @param {string} title
 * @param {string} identifier
 * @param {number} [buyVolume=0]
 * @param {number} [sellVolume=0]
 * @returns {React.ReactElement} - meter data container.
 */
export const meterContent = (title, identifier, buyVolume = 0, sellVolume = 0) => (
  <div className="mb-2">
    {title && <h5 style={{ color: '#adb5bd' }} className="mb-2">{title}</h5>}
    {identifier && <h6>{identifier}</h6>}
    <h6>
      <FormattedMessage id="property.property_show.chart_cards.exports" defaultMessage="Exports" />
    </h6>
    <div className="mb-2">{`${i18nDecimalFormat(format('.4s')(sellVolume))}Wh`}</div>
    <h6 className="mb-2">
      <FormattedMessage id="property.property_show.chart_cards.imports" defaultMessage="Imports" />
    </h6>
    <div className="mb-2">{`${i18nDecimalFormat(format('.4s')(buyVolume))}Wh`}</div>
  </div>
);

const tradeLabel = (dir) => (dir === 'sell' ? <FormattedMessage id="common.entities.exports.label" defaultMessage="Exports" />
  : <FormattedMessage id="common.entities.imports.label" defaultMessage="Imports" />);

/**
 * Build and return the content for trade cards.
 * @param {import('react-intl').IntlShape} intl - i18n react-intl
 * @param {string} label
 * @param {string} subLabel
 * @param {object} property
 * @param {object} party
 * @param {object} chartView - { aggregation, groupBy }
 * @param {DATA_AGGREGATE_BY_PROPERTY | DATA_AGGREGATE_BY_METER} chartView.aggregateBy
 * @param {DATA_GROUP_BY_COUNTERPARTY | DATA_GROUP_BY_TRADE_TYPE} chartView.groupBy
 * @param {string} title - meter title.
 * @param {string} identifier - meter identifier.
 * @returns {React.ReactElement} - trade data container.
 */
export const tradeContent = (intl, label, subLabel, property, party, chartView, title = '', identifier = '') => (
  <>
    <h5>{getLabel(intl, label, subLabel, property, title, identifier, chartView)}</h5>
    {['sell', 'buy'].map((k) => {
      if (APIConfig().MODE === PLATFORM_MODE_REBATE) {
        return (
          <React.Fragment key={k}>
            <h6>{tradeLabel(k)}</h6>
            <div className="mb-2">
              {`${i18nDecimalFormat(format('.4s')(party[k].volume))}Wh`}
            </div>
            {label.toLowerCase() !== 'retailer default' && (
              <>
                <h6><FormattedMessage id="property.property_show.chart_cards.discount_amount" defaultMessage="Discount Amount" /></h6>
                <div className="mb-2">
                  {/*
                  "common.currency.symbol"
                  NOTE: this needs to be changed –
                  the formatting (format('.2f')) is dependent on config.
                  */}
                  <FormattedMessage id="common.currency.symbol" defaultMessage="{n, number, ::currency/AUD}" values={{ n: format('.2f')(party[k].value) }} />
                </div>
              </>
            )}
          </React.Fragment>
        );
      }
      return (
        <React.Fragment key={k}>
          <h6>{tradeLabel(k)}</h6>
          <div className="mb-2">
            {/*
            NOTE: this needs to be changed – the formatting (format('.2f')) is dependent on config.
            */
              (party[k].value === 0 || (party[k].value && isNumber(party[k].value))) ? (<FormattedMessage id="property.property_show.chart_cards.currency.symbol" defaultMessage="{n, number, ::currency/AUD}" values={{ n: format('.2f')(party[k].value) }} />) : NA_SYMBOL
            }
          </div>
          <div className="mb-2">
            {`${i18nDecimalFormat(format('.4s')(party[k].volume))}Wh`}
          </div>
        </React.Fragment>
      );
    })}
  </>
);
/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement } - PropertyShowChartCards component
 */
function PropertyShowChartCards(props) {
  const {
    counterParties, hoverKeys, hoverFunc, selectedKeys, selectedFunc, chartView, meterCard, intl,
  } = props;

  if (!counterParties || counterParties.length === 0) {
    return null;
  }

  return (
    <Row className="mt-4">
      {
        counterParties.map((party) => {
          const {
            buy, identifier, key,
            label, property, sell, subLabel,
            title, tradeType, user,
          } = party;

          const cardOpacity = opacity(key, hoverKeys, selectedKeys);
          const cardRing = ring(buy, sell, key === UNTRADED_ENERGY_KEY);
          const cardAvatar = avatar(user || null, cardRing, tradeType);
          const cardId = tradeType ? ` trade-${tradeType}` : '';

          return (
            <Col className={`mb-4 property-cards${cardId}`} sm="6" lg="4" xl="3" key={key}>
              <Card
                className="h-100"
                style={{ opacity: cardOpacity, cursor: 'pointer' }}
                onMouseEnter={() => (hoverFunc(key, true))}
                onMouseLeave={() => (hoverFunc(key, false))}
                onClick={() => (selectedFunc(key))}
              >
                <CardBody>
                  <Row className="flex-nowrap">
                    <Col xs="auto">
                      {cardAvatar}
                    </Col>
                    <Col xs="auto" className="w-75">
                      {meterCard ? meterContent(title, identifier, buy?.volume, sell?.volume)
                        : tradeContent(
                          intl,
                          label,
                          subLabel,
                          property,
                          party,
                          chartView,
                          title,
                          identifier,
                        )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          );
        })
      }
    </Row>
  );
}

PropertyShowChartCards.propTypes = {
  chartView: PropTypes.shape({
    groupBy: PropTypes.oneOf([
      DATA_GROUP_BY_COUNTERPARTY,
      DATA_GROUP_BY_TRADE_TYPE,
    ]).isRequired,
    aggregateBy: PropTypes.oneOf([
      DATA_AGGREGATE_BY_PROPERTY,
      DATA_AGGREGATE_BY_METER,
    ]).isRequired,
  }).isRequired,
  counterParties: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      key: PropTypes.string,
      user: PropTypes.shape({
        id: PropTypes.string,
        givenName: PropTypes.string,
        familyName: PropTypes.string,
        email: PropTypes.string,
      }),
      buy: PropTypes.shape({
        count: PropTypes.number,
        value: PropTypes.number,
        volume: PropTypes.number,
      }),
      sell: PropTypes.shape({
        count: PropTypes.number,
        value: PropTypes.number,
        volume: PropTypes.number,
      }),
    }),
  ),
  hoverKeys: PropTypes.arrayOf(
    PropTypes.string,
  ),
  hoverFunc: PropTypes.func,
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  meterCard: PropTypes.bool,
  selectedKeys: PropTypes.arrayOf(
    PropTypes.string,
  ),
  selectedFunc: PropTypes.func,
};

PropertyShowChartCards.defaultProps = {
  counterParties: [],
  hoverKeys: [],
  hoverFunc: null,
  selectedKeys: [],
  selectedFunc: null,
  meterCard: false,
};

export default injectIntl(PropertyShowChartCards);
