import Big from 'big.js';
import { DateTime } from 'luxon';

import {
  BUY, CARBON, COMMUNITY, CONTRACTED, DATA_GROUP_BY_COUNTERPARTY,
  DATA_AGGREGATE_BY_METER, DATA_GROUP_BY_TRADE_TYPE, DATA_AGGREGATE_BY_PROPERTY,
  DIRECTIONS, NOMINATED, RESIDUAL, SOURCE_TRADES, SOURCE_HISTORIAN, TIME_ZONE_SYSTEM,
  TRADE_DIRECTION_BUY, TRADE_DIRECTION_UNSPECIFIED, TRADE_DIRECTION_SELL,
  TRADE_TYPE_CONTRACTED, TRADE_TYPE_COMMUNITY,
  TRADE_TYPE_NOMINATED, TRADE_TYPE_RESIDUAL,
  TRADE_TYPE_UNSPECIFIED, UNTRADED_ENERGY_KEY, VALUE, VOLUME, SELL,
} from 'src/util/constants';
import isNumber from 'src/util/math';
import username from 'src/util/decorators/username';

import { getTradeSummary, getTradeType, sumValuesByObjKeys } from './common';
import sortCards from '../sortCards';

// General Helpers

/**
 * Transform timestamp to ISO date string
 * @param {string} timestamp - chart primary data
 * @returns {string} - trandformed date.
 */
export const timeStampToIsoDateString = (timestamp) => DateTime.fromMillis(parseFloat(timestamp))
  .toUTC().toString();

/**
 * Returns the trade direction based on the given direction.
 * @param {BUY | SELL} direction - The direction of the trade.
 * @returns {TRADE_DIRECTION_UNSPECIFIED |TRADE_DIRECTION_BUY |
 *  TRADE_DIRECTION_SELL } - The trade direction.
 */
export const getDirection = (direction) => {
  if (!direction) {
    return TRADE_DIRECTION_UNSPECIFIED;
  }
  return direction === BUY
    ? TRADE_DIRECTION_BUY : TRADE_DIRECTION_SELL;
};

/**
 * Retrieves the property associated with a trader.
 * @param {object} trader - The trader object.
 * @returns {object | null} - The property object if found, otherwise null.
 */
export const getTraderProperty = (trader) => (trader?.tradePoint?.meter?.property || null);

/**
 * Get the entity id, falling to the default value in its absence.
 * @param {string} type - TRADE_TYPE_CONTRACTED, TRADE_TYPE_RESIDUAL...
 * @param {object} counterParty - {tradePoint, user, userId}
 * @param {string} dataKey
 * @param {boolean} groupByCounterParty - whether it's grouped by counterparty or trade type.
 * @returns {object} - {label, subLabel, key, user, property, data}
 */
export const getDataObject = (type, counterParty, dataKey, groupByCounterParty) => {
  if (!type) {
    return TRADE_TYPE_UNSPECIFIED;
  }

  const { user } = counterParty || {};
  const property = getTraderProperty(counterParty);

  let label = username(user);
  let subLabel = null;

  if (property) {
    subLabel = label;
    label = property.title;
  }

  switch (type) {
    case TRADE_TYPE_CONTRACTED:
      if (groupByCounterParty) {
        return {
          label, subLabel, key: dataKey, user, property, data: {},
        };
      }

      return {
        label: TRADE_TYPE_CONTRACTED,
        key: dataKey,
        subLabel: null,
        user: null,
        property: null,
        data: {},
      };
    case TRADE_TYPE_NOMINATED:
      if (groupByCounterParty) {
        return {
          label, subLabel, key: dataKey, user, property, data: {},
        };
      }

      return {
        label: TRADE_TYPE_NOMINATED,
        key: dataKey,
        subLabel: null,
        user: null,
        property: null,
        data: {},
      };

    case TRADE_TYPE_COMMUNITY:
      return {
        label: TRADE_TYPE_COMMUNITY,
        key: dataKey,
        subLabel: null,
        user: null,
        property: null,
        data: {},
      };
    default: // TRADE_TYPE_RESIDUAL
      return {
        label: TRADE_TYPE_RESIDUAL,
        key: dataKey,
        subLabel: null,
        user: null,
        property: null,
        data: {},
      };
  }
};

/**
 * Get the entity id, falling to the default value in its absence.
 * @param {object} entity
 * @param {string} defaultIdValue
 * @returns {string} - entity id.
 */
export const getEntityId = (entity, defaultIdValue) => (entity
  ? entity.id : defaultIdValue) || null;

/**
 * Returns the data key based on the trade type.
 * @param {string} type - The type of the trade (contracted, community, nominated, residual).
 * @param {object} counterParty - The counter party object.
 * @param {object} counterPartyProperty - The counter party property object.
 * @param {object} meter - The meter object.
 * @param {string} ruleId - The rule ID.
 * @param {boolean} groupByCounterParty - whether it's grouped by counterparty or trade type.
 * @returns {string} - The data key.
 */
export const getDataKey = (
  type,
  counterParty,
  counterPartyProperty,
  meter,
  ruleId,
  groupByCounterParty,
) => {
  if (!type) {
    return TRADE_TYPE_UNSPECIFIED;
  }

  const { user } = counterParty || {};

  switch (type) {
    case TRADE_TYPE_CONTRACTED:
      if (groupByCounterParty) {
        return [
          getEntityId(user, ruleId),
          (counterPartyProperty ? getEntityId(counterPartyProperty, null) : null),
          (meter ? getEntityId(meter, null) : null),
        ].filter(Boolean).join('-');
      }
      return CONTRACTED;
    case TRADE_TYPE_NOMINATED:
      if (groupByCounterParty) {
        return [
          getEntityId(user, ruleId),
          (counterPartyProperty ? getEntityId(counterPartyProperty, null) : null),
          (meter ? getEntityId(meter, null) : null),
        ].filter(Boolean).join('-');
      }
      return NOMINATED;
    case TRADE_TYPE_COMMUNITY:
      return COMMUNITY;
    default: // TRADE_TYPE_RESIDUAL
      return RESIDUAL;
  }
};

// Meter data - helpers

/**
 * Builds chart meter data for aggregated view.
 * @param {object} mainData - chart primary data.
 * @returns {object} - meter data (timestamp based).
 */
export const buildChartMeterDataAggregated = (mainData) => {
  const finalResp = {};
  if (!mainData) return finalResp;
  let value = Big(0);
  let carbon = Big(0);

  const timezone = mainData.property?.timezone || TIME_ZONE_SYSTEM;

  DIRECTIONS.forEach((dir) => {
    finalResp[dir] = {};
    let flags = [];
    if (mainData[dir]) {
      Object.keys(mainData[dir].data).forEach((timestamp) => {
        const { meterDataAggregates } = mainData[dir].data[timestamp];
        if (Object.keys(meterDataAggregates).length === 1) {
          const meterId = Object.keys(meterDataAggregates)[0];
          carbon = Big(meterDataAggregates[meterId].carbon);
          value = meterDataAggregates[meterId].value;
          flags = meterDataAggregates[meterId].flags;
        }
        if (Object.keys(meterDataAggregates).length > 1) {
          value = sumValuesByObjKeys(Object.values(meterDataAggregates), VALUE);
          carbon = sumValuesByObjKeys(Object.values(meterDataAggregates), CARBON);
          Object.keys(meterDataAggregates).forEach((meterId) => {
            meterDataAggregates[meterId].flags.forEach((flag) => {
              if (flags.map((f) => f.identifier).indexOf(flag.identifier) === -1) {
                flags.push(flag);
              }
            });
          });
        }
        const formattedTimestamp = timeStampToIsoDateString(timestamp);
        const meterDataSeries = {
          timestamp: DateTime.fromISO(formattedTimestamp, { zone: timezone }),
          value: Number(value),
          flags,
          carbon,
        };
        finalResp[dir][formattedTimestamp] = meterDataSeries;
      });
    }
  });

  return finalResp;
};

/**
 * Builds chart meter data for non aggregated view.
 * @param {object} mainData - main data object
 * @returns {object} - object expected byt chart meter for the non aggregated view.
 */
export const buildChartMeterDataByMeter = (mainData) => {
  const finalResp = {};

  if (!mainData) return finalResp;

  const timezone = mainData.property?.timezone || TIME_ZONE_SYSTEM;

  mainData.meters.forEach((meter) => {
    const { id: meterId, title, identifier } = meter;

    DIRECTIONS.forEach((dir) => {
      if (!finalResp[meterId]) {
        finalResp[meterId] = {
          buy: {},
          sell: {},
          title,
          identifier,
        };
      }

      Object.keys(mainData[dir].data).forEach((timestamp) => {
        const dataPoint = mainData[dir].data[timestamp];
        const { meterDataAggregates } = dataPoint;
        const { value, flags, carbon } = meterDataAggregates[meterId] || {};
        if (!flags) return;
        const formattedTimestamp = new Date(Number(timestamp)).toISOString();
        const timestampData = {
          timestamp: DateTime.fromISO(formattedTimestamp, { zone: timezone }),
          value,
          flags,
          carbon: Big(carbon),
        };

        finalResp[meterId][dir][formattedTimestamp] = timestampData;
      });
    });
  });
  return finalResp;
};

/**
 * Build and returns the chart meter data.
 * @param {object} mainData - chart primary data.
 * @param {boolean} isByMeter - returns against meter level if true otherwise property level
 * @returns {object} - chart meter data.
 */
export const buildChartMeterData = (mainData, isByMeter) => {
  if (!mainData) {
    return {};
  }

  if (isByMeter) {
    return buildChartMeterDataByMeter(mainData);
  }
  return buildChartMeterDataAggregated(mainData);
};

/**
 * Build data for meter cards - shown in meter view below the meter chart.
 * @param {object} mainData
 * @returns {object} - meter cards data.
 */

export const buildMeterCardsData = (mainData) => {
  if (!mainData) {
    return null;
  }
  const finalResp = {};
  const { meters } = mainData;
  DIRECTIONS.forEach((dir) => {
    const { data = {} } = mainData[dir];
    Object.keys(data).forEach((timestamp) => {
      const { meterDataAggregates: meter = {} } = data[timestamp];
      Object.keys(meter).forEach((meterId) => {
        const { value } = meter[meterId];
        const meterNode = meters.filter((node) => node.id === meterId)[0];
        const { title, identifier } = meterNode;
        if (value) {
          if (finalResp[meterId] && finalResp[meterId][dir]) {
            const { volume, count } = finalResp[meterId][dir];
            finalResp[meterId][dir] = {
              ...finalResp[meterId][dir],
              volume: volume.plus(value),
              count: count + 1,
            };
            return;
          }
          finalResp[meterId] = {
            ...finalResp[meterId],
            key: meterId,
            title,
            identifier,
            [dir]: {
              volume: Big(value),
              count: 1,
            },
          };
        }
      });
    });
  });
  const metersIds = meters.map((meter) => meter.id);
  // Add meters that have no data needed for dispaying meter cards
  metersIds.forEach((meterId) => {
    if (!finalResp[meterId]) {
      finalResp[meterId] = {
        key: meterId,
        title: meters.find((node) => node.id === meterId).title,
        identifier: meters.find((node) => node.id === meterId).identifier,
        buy: {
          volume: Big(0),
          count: 0,
        },
        sell: {
          volume: Big(0),
          count: 0,
        },
      };
    }
  });

  return Object.values(finalResp);
};

// Trade data - helpers

/**
 * Returns the correct counter party based on the trade type and direction
 * @param {object} rules - trade rules
 * @param {string} ruleId - trade rule id
 * @param {TRADE_TYPE_CONTRACTED | TRADE_TYPE_NOMINATED|
 * TRADE_TYPE_COMMUNITY| TRADE_TYPE_RESIDUAL} type - trade types
 * @param {BUY | SELL} direction - trade direction
 * @returns {object | null} - buyer, seller, null
 */
export const getCounterParty = (rules, ruleId, type, direction) => {
  if (rules
    && rules.length > 0 && ruleId && type && direction) {
    const filteredRule = rules?.filter((rule) => rule.id === ruleId);
    const rule = filteredRule && filteredRule[0];
    if (rule) {
      if (type === TRADE_TYPE_CONTRACTED || type === TRADE_TYPE_NOMINATED) {
        if (direction === TRADE_DIRECTION_BUY) {
          return rule.seller;
        }
        return rule.buyer;
      }
    }
  }

  return null;
};

const allTradeTypes = ['contracted', 'nominated', 'community', 'residual'];

/**
 * Transform the trade data into the data format for the counterparty
 * cards shown below the trade chart.
 * @param {object} tradeData
 * @param {object} tradeData.buy
 * @param {Array<object>} tradeData.buy.contracted
 * @param {Array<object>} tradeData.buy.nominated
 * @param {Array<object>} tradeData.buy.community
 * @param {Array<object>} tradeData.buy.residual
 * @param {object} tradeData.sell
 * @param {Array<object>} tradeData.sell.contracted
 * @param {Array<object>} tradeData.sell.nominated
 * @param {Array<object>} tradeData.sell.community
 * @param {Array<object>} tradeData.sell.residual
 * @param {boolean} isByMeter - returns against meter level if true otherwise property level
 * @returns {object} -counterparty data.
 */
export const getPropertyShowChartCards = (tradeData, isByMeter) => {
  const counterParties = {};
  if (!tradeData) {
    return counterParties;
  }
  DIRECTIONS.forEach((dir) => {
    allTradeTypes.forEach((type) => {
      if (!tradeData[dir][type]) {
        return;
      }
      Object.values(tradeData[dir][type])?.forEach((series) => {
        const {
          key, label, subLabel, user, data, property, meter,
        } = series;
        const { title, identifier } = meter;

        if (!key) { return; }
        if (!(key in counterParties)) {
          counterParties[key] = {
            key,
            tradeType: type,
            label,
            subLabel,
            property,
            user,
            title: isByMeter ? title : '',
            identifier: isByMeter ? identifier : '',
            buy: { value: 0, volume: 0, count: 0 },
            sell: { value: 0, volume: 0, count: 0 },
          };
        }
        Object.values(data).forEach((datum) => {
          const { value, volume } = datum;
          counterParties[key][dir].count += 1;
          counterParties[key][dir].value = Number(Big(counterParties[key][dir].value)
            .plus(value));
          counterParties[key][dir].volume = Number(Big(counterParties[key][dir].volume)
            .plus(volume));
        });
      });
    });
  });
  return counterParties;
};

/**
 * Builds and returns trade data per meter
 * @param {object} mainData - chart main data
 * @param {string} aggregation - interval of the chart (day or half an hr interval)
 * @param {object} chartView - { aggregateBy, groupBy }
 * @param {DATA_AGGREGATE_BY_PROPERTY | DATA_AGGREGATE_BY_METER} chartView.aggregateBy
 * @param {DATA_GROUP_BY_COUNTERPARTY | DATA_GROUP_BY_TRADE_TYPE} chartView.groupBy
 * @returns {object} - trade data.
 */
export const getTradeData = (mainData, aggregation, chartView) => {
  const resp = {
    buy: {
      contracted: {},
      nominated: {},
      community: {},
      residual: {},
      summary: { value: 0, volume: 0, carbon: 0 },
    },
    sell: {
      contracted: {},
      nominated: {},
      community: {},
      residual: {},
      summary: { value: 0, volume: 0, carbon: 0 },
    },
  };
  if (!mainData || !chartView) {
    return resp;
  }
  const timezone = mainData.property?.timezone || TIME_ZONE_SYSTEM;
  const { aggregateBy, groupBy } = chartView;
  const isByMeter = aggregateBy === DATA_AGGREGATE_BY_METER;
  const groupByCounterParty = groupBy === DATA_GROUP_BY_COUNTERPARTY;

  DIRECTIONS.forEach((dir) => {
    if (!mainData[dir].data) {
      return;
    }

    Object.keys(mainData[dir].data)?.forEach((timestamp) => {
      const { tradeSetSummaries } = mainData[dir].data[timestamp] || {};
      if (!tradeSetSummaries) {
        return;
      }

      Object.keys(tradeSetSummaries)?.forEach((ruleId) => {
        const trade = tradeSetSummaries[ruleId] || {};
        const {
          meterId, type, carbon = 0, value = 0, volume = 0,
        } = trade || {};

        if (!type) {
          return;
        }

        const tradeType = getTradeType(type);
        const { rules } = mainData[dir];
        const counterParty = getCounterParty(rules, ruleId, type, getDirection(dir));
        const counterPartyProperty = getTraderProperty(counterParty);
        const t = resp[dir][tradeType];
        const key = getDataKey(
          type,
          counterParty,
          counterPartyProperty,
          null,
          ruleId,
          groupByCounterParty,
        );
        const dataKey = isByMeter ? `${key}_${meterId}` : key;
        if (!(dataKey in t)) {
          t[dataKey] = getDataObject(type, counterParty, dataKey, groupByCounterParty);
        }

        // Add counterparty property data if available
        t[dataKey].property = counterPartyProperty;

        // Add meter details
        const rule = rules?.filter((r) => r.id === ruleId)[0];
        const { buyer, seller } = rule || {};
        const trader = dir === BUY ? buyer : seller;
        const { title, identifier } = trader?.tradePoint?.meter || {};
        t[dataKey].meter = { title, identifier };

        const dataSeries = t[dataKey].data;
        const date = timeStampToIsoDateString(timestamp);
        dataSeries[date] ||= {
          interval: {
            timestamp: DateTime.fromMillis(
              parseFloat(timestamp),
              { zone: timezone },
            ),
            length: aggregation,
          },
          value: 0,
          volume: 0,
          carbon: 0,
        };

        const aggregateCarbonByDate = Big(dataSeries[date].carbon).plus(carbon);
        const aggregateValueByDate = Big(dataSeries[date].value).plus(value);
        const aggregateVolumeByDate = Big(dataSeries[date].volume).plus(volume);

        dataSeries[date].carbon = Number(aggregateCarbonByDate);
        dataSeries[date].value = Number(aggregateValueByDate);
        dataSeries[date].volume = Number(aggregateVolumeByDate);

        const aggregateCarbonSummary = Big(resp[dir].summary.carbon).plus(carbon);
        const aggregateValueSummary = Big(resp[dir].summary.value).plus(value);
        const aggregateVolumeSummary = Big(resp[dir].summary.volume).plus(volume);

        resp[dir].summary.carbon = Number(aggregateCarbonSummary);
        resp[dir].summary.value = Number(aggregateValueSummary);
        resp[dir].summary.volume = Number(aggregateVolumeSummary);
      });
    });
  });
  return resp;
};

/**
 * Builds the untraded data series to be added to the chartTradeData
 * @param {object} mainData - time series data (meter, trades and untraded)
 * @returns {object}  - {buy: {timestamp: {interval: timestamp, length}, volume, value, carbon},
 * sell: {timestamp: {interval: timestamp, length}, volume, value, carbon}
 */
export const buildUntradedDataSeries = (mainData) => {
  const finalResp = { buy: {}, sell: {} };
  if (!mainData) {
    return finalResp;
  }

  const { meters, property } = mainData;
  const timezone = property?.timezone || TIME_ZONE_SYSTEM;

  DIRECTIONS.forEach((dir) => {
    if (!mainData[dir].data) {
      return;
    }
    Object.entries(mainData[dir].data)?.forEach(([timestamp,
      { meterDataAggregates, tradeSetSummaries, untradedDataAggregates }]) => {
      let energy = Big(0);
      let untraded = Big(0);
      let untradedValue = Big(0);
      let carbon = Big(0);

      Object.keys(meterDataAggregates)?.forEach((meterId) => {
        const meter = meterDataAggregates[meterId];
        const { value } = meter;

        energy = isNumber(value) ? energy.plus(value) : energy;
      });

      const noTrades = Object.values(tradeSetSummaries)?.every((trade) => !trade.volume);
      const untradedDataList = Object.values(untradedDataAggregates);
      if (noTrades) {
        untraded = untraded.plus(energy);
      } else {
        // one meter
        if (Object.keys(meterDataAggregates).length === 1) {
          const filteredTradeSetSummaries = Object.values(tradeSetSummaries)
            ?.filter(({ volume }) => volume);
          untraded = energy.minus(sumValuesByObjKeys(filteredTradeSetSummaries, VOLUME));
        }
        // multiple meters
        if (Object.keys(meterDataAggregates)?.length > 1) {
          if (untradedDataList?.length === 1) {
            const { volume } = untradedDataList && untradedDataList[0];
            untraded = untraded.plus(volume);
          }
          untraded = sumValuesByObjKeys(untradedDataList, VOLUME);
        }
      }

      if (untradedDataList.length >= 1) {
        untradedValue = Number(untraded) === 0
          ? 0
          : sumValuesByObjKeys(untradedDataList, VALUE);
        carbon = sumValuesByObjKeys(untradedDataList, CARBON);
      }
      const formattedTimestamp = timeStampToIsoDateString(timestamp);
      const { aggregation } = meters?.find((meter) => meter.externalIdentifier) || {};
      const untradedDataSeries = {
        interval: {
          timestamp: DateTime.fromISO(
            formattedTimestamp,
            { zone: timezone },
          ),
          length: aggregation,
        },
        volume: Number(untraded) === 0 ? NaN : Number(untraded),
        value: Number(untradedValue) === 0 ? NaN : Number(untradedValue),
        carbon: Number(carbon) === 0 ? NaN : Number(carbon),
      };
      finalResp[dir][formattedTimestamp] = untradedDataSeries;
    });
  });
  return finalResp;
};

/**
 * Checks if the mainData has any untraded data aggregates.
 * @param {object} mainData - The main data object.
 * @returns {boolean} - Returns true if there is untraded data, otherwise false.
 */
export const hasUntraded = (mainData) => {
  if (!mainData) {
    return false;
  }

  return (DIRECTIONS.some(
    (dir) => Object.keys(mainData[dir].data)?.some((timestamp) => {
      const { untradedDataAggregates } = mainData[dir].data[timestamp];
      return Object.keys(untradedDataAggregates).some(
        (ruleId) => untradedDataAggregates[ruleId].volume > 0,
      );
    }),
  ));
};

/**
 * Conslidate all the trades.
 * @param {object} tradeData
 * @returns {object} - consolidated trades.
 */
export const consolidateTrades = (tradeData) => {
  const { buy: tradeBuy, sell: tradeSell } = tradeData || {};
  const resp = {
    buy: [
      ...Object.values(tradeBuy.contracted),
      ...Object.values(tradeBuy.nominated),
      ...Object.values(tradeBuy.community),
      ...Object.values(tradeBuy.residual),
    ],
    sell: [
      ...Object.values(tradeSell.contracted),
      ...Object.values(tradeSell.nominated),
      ...Object.values(tradeSell.community),
      ...Object.values(tradeSell.residual),
    ],
  };
  return resp;
};

/**
 * Tranform the normalised trade data into the format required for the trade chart.
 * @param {object} mainData
 * @returns {object} - { untradedBuy, untradedSell }.
 */
const addUntraded = (mainData) => {
  const untradedDataSeries = buildUntradedDataSeries(mainData);
  const untradedBuy = {
    label: UNTRADED_ENERGY_KEY,
    key: UNTRADED_ENERGY_KEY,
    data: untradedDataSeries.buy,
  };
  const untradedSell = {
    label: UNTRADED_ENERGY_KEY,
    key: UNTRADED_ENERGY_KEY,
    data: untradedDataSeries.sell,
  };
  return { untradedBuy, untradedSell };
};

/**
 * Tranform the normalised trade data into the format required for the trade chart.
 * @param {object} tradeDataNormalised
 * @param {boolean} isAggregated - view of the chart (aggregated and meter).
 * @param {SOURCE_TRADES | SOURCE_HISTORIAN} source
 * @param {object} mainData
 * @returns {object} - trade data for the chart.
 */
export const extractTradeData = (
  tradeDataNormalised,
  isAggregated,
  source,
  mainData,
) => {
  if (!tradeDataNormalised || Object.keys(tradeDataNormalised)?.length === 0) {
    return {};
  }
  if (isAggregated) {
    const resp = consolidateTrades(tradeDataNormalised);

    if (source === SOURCE_TRADES && hasUntraded(mainData)) {
      const { untradedBuy, untradedSell } = addUntraded(mainData);
      resp.buy.push(untradedBuy);
      resp.sell.push(untradedSell);
    }
    return resp;
  }
  const finalData = { buy: [], sell: [] };
  Object.keys(tradeDataNormalised).forEach((meterId) => {
    const tradeData = tradeDataNormalised[meterId] || {};
    const resp = consolidateTrades(tradeData);
    finalData.buy = [...finalData.buy, ...resp.buy];
    finalData.sell = [...finalData.sell, ...resp.sell];
  });

  if (source === SOURCE_TRADES && hasUntraded(mainData)) {
    const { untradedBuy, untradedSell } = addUntraded(mainData);

    finalData.buy.push(untradedBuy);
    finalData.sell.push(untradedSell);
  }
  return finalData;
};

/**
 * Builds the data expected by the chartTrade component.
 * @param {object} mainData - time series data (meter, trades and untraded)
 * @param {string} tradeAggregation - "P1D" or "PT30M
 * @param {object} chartView - { aggregateBy, groupBy }
 * @param {DATA_AGGREGATE_BY_PROPERTY | DATA_AGGREGATE_BY_METER} chartView.aggregateBy
 * @param {DATA_GROUP_BY_COUNTERPARTY | DATA_GROUP_BY_TRADE_TYPE} chartView.groupBy
 * @param {SOURCE_TRADES | SOURCE_HISTORIAN} source
 * @returns {object} - time series trade data
 */
export const buildChartTradeData = (mainData, tradeAggregation, chartView, source) => {
  const tradeData = getTradeData(mainData, tradeAggregation, chartView);

  const chartTradeData = extractTradeData(tradeData, true, source, mainData);

  return chartTradeData;
};

/**
 * Returns an array of objects expected by PropertyShowChartCards on the Trades view
 * @param {object} mainData - time series data (meter, trades and untraded)
 * @param {string} tradeAggregation - "P1D" or "PT30M
 * @param {object} chartView - { aggregateBy, groupBy }
 * @param {DATA_AGGREGATE_BY_PROPERTY | DATA_AGGREGATE_BY_METER} chartView.aggregateBy
 * @param {DATA_GROUP_BY_COUNTERPARTY | DATA_GROUP_BY_TRADE_TYPE} chartView.groupBy
 * @returns {Array} - tradeCardsData
 */
export const getTradeCardsData = (mainData, tradeAggregation, chartView) => {
  let tradeCardsData = [];
  if (!mainData || !tradeAggregation || !chartView) {
    return tradeCardsData;
  }
  const { aggregateBy } = chartView;
  const isByMeter = aggregateBy === DATA_AGGREGATE_BY_METER;
  const tradeData = getTradeData(mainData, tradeAggregation, chartView);
  const propertyShowChartCards = getPropertyShowChartCards(tradeData, isByMeter);
  tradeCardsData = sortCards(Object.values(propertyShowChartCards));
  return tradeCardsData;
};

// Chart summary data helpers

/**
 * Transforms the main data set into the data set used in the Chart Summary component.
 * @param {object} mainData
 * @returns {object} chart summary data, split into buy and sell directions.
 */
export const getChartSummaryData = (mainData) => {
  const tradeSummary = getTradeSummary(mainData);
  const finalResp = { buy: {}, sell: {} };

  Object.keys(tradeSummary)?.forEach((dir) => {
    finalResp[dir].energy = Big(0);
    finalResp[dir].tradedEnergy = Big(0);
    finalResp[dir].untradedValue = Big(0);
    finalResp[dir].untradedVolume = Big(0);
    finalResp[dir].untradedCount = 0;
    finalResp[dir].tradedValue = Big(0);
    finalResp[dir].rebatedEnergy = Big(0);
    finalResp[dir].netDiffValue = Big(0);

    Object.keys(tradeSummary[dir])?.forEach((timestamp, index) => {
      const {
        energy, rebatedEnergy, tradedEnergy,
        untradedValue, untradedVolume, tradedValue, netDiffValue,
      } = tradeSummary[dir][timestamp];

      const finalIndex = index === Object.keys(tradeSummary[dir]).length - 1;
      const aggregatedEnergy = finalResp[dir].energy.plus(energy);
      const aggregatedTradedEnergy = finalResp[dir].tradedEnergy.plus(tradedEnergy);
      const aggregatedUntradedValue = finalResp[dir].untradedValue.plus(untradedValue);
      const aggregatedUntradedVolume = finalResp[dir].untradedVolume.plus(untradedVolume);
      const aggregatedRebatedEnergy = finalResp[dir].rebatedEnergy.plus(rebatedEnergy);
      const aggregatedTradedValue = finalResp[dir].tradedValue.plus(tradedValue);
      const aggregatedNetDiffValue = finalResp[dir].netDiffValue.plus(netDiffValue);
      if (Number(untradedVolume) > 0) {
        finalResp[dir].untradedCount += 1;
      }

      finalResp[dir].energy = finalIndex ? Number(aggregatedEnergy) : aggregatedEnergy;
      finalResp[dir].tradedEnergy = finalIndex ? Number(aggregatedTradedEnergy)
        : aggregatedTradedEnergy;
      finalResp[dir].untradedValue = finalIndex ? Number(aggregatedUntradedValue)
        : aggregatedUntradedValue;
      finalResp[dir].untradedVolume = finalIndex ? Number(aggregatedUntradedVolume)
        : aggregatedUntradedVolume;
      finalResp[dir].rebatedEnergy = finalIndex ? Number(aggregatedRebatedEnergy)
        : aggregatedRebatedEnergy;
      finalResp[dir].tradedValue = finalIndex ? Number(aggregatedTradedValue)
        : aggregatedTradedValue;
      finalResp[dir].netDiffValue = finalIndex ? Number(aggregatedNetDiffValue)
        : aggregatedNetDiffValue;
    });
  });
  return finalResp;
};
